import { useTranslation } from "react-i18next";

export const Maintenance = () => {
    const { t } = useTranslation();

    return(
        <div className="w-full p-4 h-full min-h-[100vh] flex flex-col  gap-3 justify-center items-center">
            <div className="flex gap-3">
                <div className="rounded bg-last min-w-[100px] min-h-[100px] text-center text-white flex justify-center items-center text-4xl">
                </div>
                <div className="rounded bg-secondary min-w-[100px] min-h-[100px] text-center text-white flex justify-center items-center text-4xl">
                </div>
                <div className="rounded bg-primary min-w-[100px] min-h-[100px] text-center text-white flex justify-center items-center text-4xl">
                </div>
            </div>
            <div className="text-center">
                { t('maintenance') }
            </div>
        </div>

        
    );

}
