import { Button } from "rsuite";
import { IoIosChatboxes } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useState } from "react";
import { useTranslation } from "react-i18next";
export const ContactCTA = () => {
    const { t } = useTranslation('whatsappWidget');
    const [pannelOpen, setPannelOpen] = useState(false);
    return (

        <div className="contactCTA flex justify-between flex-col mb-3">
        { pannelOpen && 

                <div className="mx-10 flex flex-col justify-between drop-shadow-xl " >
                    <div className="bg-primary w-full h-full rounded min-h-[50px]  text-white text-center pb-5 pt-5 text-lg "  >
                    {t('faster-contact')}
                        <div className="w-full flex  justify-center profile ">< div className="circle  bg-green-600"> <div className="whatsapp"><FaWhatsapp /></div> </div>  </div>
                    </div>
                    <div className="bg-white  min-h-[200px] px-6  pt-10 rounded mb-5  ">
                        <div className="flex items-center gap-3 "> 
                            {/* <div className="circle  min-w-[50px] bg-last">Z</div> */}

                            <div className="  bg-main mt-2 p-3">

                                <span> {t('cta-contact-whatsapp')}  </span>
                            </div> 
        
                        
                        </div>
                        <div className="w-full flex  mb-5 mt-10 justify-center"> 
                            <hr/>
                            <Button onClick={() => window.open("https://wa.me/+310643693567")} className="bg-secondary rounded drop-shadow-md">{t('start-chat')}</Button>
                        </div>
                    </div>
                </div>
            }
            <div className="w-full flex justify-end items-center">
                <Button onClick={() => setPannelOpen(!pannelOpen)} ripple={false} className="bg-secondary rounded mr-2 max-w-[110px] min-w-[60px] min-h-[50px]   ">
                    <span className="text-white color-white p-1 question"> <IoIosChatboxes /></span>
                </Button>
            </div>
        </div>
    );
}