import { Button, Input } from "rsuite";
import SignatureCanvas from 'react-signature-canvas';
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

export const PDFSigner = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({});
    const [signature, setSignature] = useState(null);
    const sigCanvas = useRef({});
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [documentSigned, setDocumentSigned] = useState(null); 
    const clientParam = params.get('client');
    const hashParam = params.get('hash');
    const iframeSrc = `http://localhost/PDFs/file-fetch.php?client=${clientParam}&hash=${hashParam}`;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setSignature(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (sigCanvas.current.isEmpty()) {
            alert(t("sign.please_provide_signature"));
            return;
        }
        const signatureDataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setSignature(signatureDataUrl);

        console.log(t("sign.form_data") + ':', formData?.name);
        console.log(t("sign.signature_added") + ':', signatureDataUrl);

        sendPostRequest(formData, signatureDataUrl);
    };

    useEffect(() => {
        axios.post('http://localhost/PDFs/save-data.php', {
            hash: hashParam,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            if(response.data.signature){
            document.getElementById("name").value = response.data.name; 
            document.getElementById("name").innerHTML = response.data.name; 
            document.getElementById("name").disabled = true;
            setDocumentSigned(true);
            sigCanvas.current.fromDataURL(response.data.signature);
            }

            if(hashParam == null && response.data.code != 1){ 
                document.getElementById("form").hidden = true; 

            }
          }).catch(error => {
            console.log(error);
            
          });
        
    }, []);

    const sendPostRequest = async (formData, signatureDataUrl) => {
        try {
            axios.post('http://localhost/PDFs/save-data.php', {
                name: formData?.name,
                signature: signatureDataUrl,
                client: clientParam,
                hash: hashParam,
              },
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(response => {
                window.location.reload();
              }).catch(error => {

              });
            
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return(
        <div className="min-h-[80vh] px-3">
            <div className="w-full mt-10 flex justify-center items-center">
                <a href={`https://zwlsoftware.` + t('domain.ext') }><img src="./zwlBL.png" height={160} width={160}/></a>
            </div>
            <h1 className="text-black text-center mt-10 mb-10">{t("sign.title")}</h1>
            <iframe src={iframeSrc} width="100%" height="600px"></iframe>
            <form className="md:flex flex-col items-center my-10" onSubmit={handleSubmit} id="form">

                {documentSigned && (
                    <h5 className="text-black"> {t('sign.complete')} </h5>
                    
                )}

                <div className="md:w-1/2">
                    <p>{t("sign.fill_and_sign")}</p>
                    <input
                        name="name"
                        placeholder={t("sign.name_placeholder")}
                        className="block input w-full mt-1 mb-2 p-2 border"
                        value={formData.name}
                        onChange={handleInputChange}
                        id="name"
                        required
                    />
                    <div className="border p-4 overflow-hidden">
                        <p>{t("sign.signature")}:</p>
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor="black"
                            canvasProps={{ width: 500, height: 200, className: "sigCanvas bg-gray-200" }}
                        />
                        <button type="button" onClick={clearSignature} className="mt-2 text-blue-500">
                            {t("sign.reset")}
                        </button>
                    </div>
                    <Button type="submit" className="contact-btn-1 rounded text-white mt-4 p-2 bg-blue-500 text-white">
                        <span className="text-white">{t("sign.sign_button")}</span>
                    </Button>
                </div>
            </form>
        </div>
    );
}
