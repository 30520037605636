import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t } = useTranslation('footer');
    // NL003723128B86 KvK 82743037
    const footerLinks = [
        {
            title: 'our-details',
            links: [
                { text: '© ZWL Software · 2024', hrefKey: null },
                { text: 'Udenseweg 2', hrefKey: null },
                { text: 'Uden 5405 PA', hrefKey: null },
                { text: 'no-visitor-adress', hrefKey: null }
            ]
        },
        {
            title: 'services',
            links: [
                { text: 'applicatie-ontwikkeling', hrefKey: 'link-applicatie-ontwikkeling' },
                { text: 'marketing-seo', hrefKey: 'link-marketing-seo' },
                { text: 'website-copywriting', hrefKey: 'link-website-copywriting' },
                { text: 'webdesign-op-maat', hrefKey: 'link-webdesign-op-maat' },
                { text: 'website-laten-maken', hrefKey: 'link-website-laten-maken' },
                { text: 'website-templates', hrefKey: 'link-website-templates' },
                { text: 'website-hosting', hrefKey: 'link-website-hosting' },
                { text: 'domein', hrefKey: 'link-domein' }
            ]
        },
        {
            title: 'navigation',
            links: [
                { text: 'home', hrefKey: 'link-home' },
                { text: 'website-laten-maken', hrefKey: 'link-website-laten-maken' },
                { text: 'Cases', hrefKey: 'link-cases' },
                { text: 'contact', hrefKey: 'link-contact' }
            
            ]
        },
        {
            title: 'customer-support',
            links: [
                { text: 'website-check', hrefKey: "link-website-check" },
                { text: 'website-maintenance', hrefKey: "/contact" },
                { text: 'request-support', hrefKey: "/contact" },
                { text: 'get-contact', hrefKey: "/contact" }
            ]
        },
        {
            title: 'info',
            links: [
                { text: 'contact', href: '/' },
                { text: 'news', hrefKey: null },
                { text: 'blog', hrefKey: null },
                { text: 'affiliate', hrefKey: null },
                { text: '‎', hrefKey: null },
                { text: 'KvK: 82743037', hrefKey: null },
                { text: 'vat', hrefKey: null },
                { text: 'privacy', hrefKey: "link-privacy" },
                { text: 'terms', hrefKey: "link-terms" }

            ]
        }
    ];

        return (
            <div className="footer flex flex-col justify-end bg-gray-800 text-white pt-4">
                
                <div className="footer-middle  flex flex-col justify-between py-10 md:px-0 px-6">
                    <div className="md:6 desktop-content min-h-[350px] mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                        {footerLinks.map((section, index) => {
                            if (section.title === 'services') {
                                return (
                                    <div className='mt-5 text-start ' key={index}>
                                        <h4 className="font-bold mb-2">{ t(section.title) }</h4>
                                        {section.links.map((link, linkIndex) => (
                                        
                                            <p key={linkIndex} className="text-white w-full block hover:text-white" >
                                                {link.hrefKey !== '' ? (           
                                                    <a href={t(link.hrefKey)}>{t(link.text)}</a>)
                                                : 
                                                (<>
                                                    <span>{t(link.text) }</span>
                                                </>)}
                                    
                                            </p>
                                           
                                        ))}
                                        {/* Render "Navigatie" links directly after "Domein" */}
                                        <h4 className="font-bold mt-8    mb-2">{t('navigation')}</h4>
                                        {footerLinks.find(sec => sec.title === 'navigation').links.map((link, linkIndex) => (
                                             
                                            <p key={linkIndex} className="text-white w-full block hover:text-white" >
                                                {link.hrefKey !== '' ? (           
                                                    <a href={t(link.hrefKey)}>{t(link.text)}</a>)
                                                : 
                                                (<>
                                                    <span> {t(link.text) }</span>
                                                </>)}
                                    
                                            </p>
                                          
                                        ))}
                                    </div>
                                );
                            } else if (section.title === 'navigation') {
                                // Do not render separately if already included in "Diensten"
                                return null;
                            } else {
                                return (
                                    <div className='mt-5 text-start ' key={index}>
                                        <h4 className="font-bold mb-2">{ t(section.title) }</h4>
                                        {section.links.map((link, linkIndex) => (
                                           
                                           <p key={linkIndex} className="text-white w-full block hover:text-white" >
                                                {link.hrefKey !== null ? (           
                                                    <a href={t(link.hrefKey)}>{t(link.text)}</a>)
                                                : 
                                                (<>
                                                    <span>{t(link.text) }</span>
                                                </>)}
                                    
                                            </p>
                                        ))}
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <div class="row mt-10">
                        <div class="col-md-12 copy">
                            <p class="text-center"> </p>
                    
                        </div>
                    </div>
                </div>
            </div>
        );
    };
