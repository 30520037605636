
// Header.js
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'rsuite';

import { Turn as Hamburger } from 'hamburger-react'
import { useTranslation } from 'react-i18next';
import useScrollPosition from '../hooks/useScrollPosition';
import { BiPhoneCall } from 'react-icons/bi';
import { FaPhone, FaPhoneVolume } from 'react-icons/fa';
import { Progress } from 'rsuite';
import InfiniteScroll from './InfiniteScroll';

const PageProgressWrapper = () => {
    const [progress, setProgress] = useState(0);
    const [isBlog, setIsBlog] = useState(false);

   
    useEffect(() => {
      const handleScroll = () => {
        const elem = document.getElementById('main-content');
        if (!elem) {  setIsBlog(false); return};
        setIsBlog(true);
        const contentHeight = elem.clientHeight;
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
  
        const scrollableHeight = contentHeight + 350 - windowHeight;
        const scrolledPercentage = (scrollPosition / scrollableHeight) * 100;
  
        setProgress(Math.min(scrolledPercentage, 100)); // Ensure it doesn't exceed 100%
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    return (
      <div >
        { isBlog && (
            <Progress.Line className='scroller'  strokeLinecap={'square'} percent={progress} strokeColor="#ff66b3" height={2}  showInfo={false} />
        )}
      </div>
    );
  };

export const Header = () => {
    const currentPath = window.location.pathname;
    const scrollPosition = useScrollPosition();
    const [isSticky, setIsSticky] = React.useState(false);
    const { t } = useTranslation('headerBanner');

    const scrollerText = [
        t('scrollerText.t1'),
        t('scrollerText.t2'),
        t('scrollerText.t3')
    ];

    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState('0px');
    const contentRef = useRef(null);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isOpen) {
            setHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setHeight('0px');
            console.log("close");
            
        }
    }, [isOpen]);

    const navItems = [
        { name: t('header.home'), link:  t('header.link-home') },
    { name: t('header.create-website'), link:  t('header.link-create-website') },
        { name: t('header.cases'), link: t('header.link-cases') }
    ];
    
    const normalizePath = (path) => path.replace(/^\/|\/$/g, '');

    const isActive = (path) => {
        const normalizedPath = normalizePath(path);
        const normalizedCurrentPath = normalizePath(currentPath);
        
        const active = normalizedPath === normalizedCurrentPath;

        // Debugging logs to check normalized values
        // console.log(`Normalized path: ${normalizedPath}, Normalized current path: ${normalizedCurrentPath}, Active: ${active}`);

        return active ? true : false;
    };

    // Adjust the scroll threshold percentage here
    const threshold = 0.08 * window.innerHeight; // 25% of viewport height

    useEffect(() => {
        if (scrollPosition > threshold) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    }, [scrollPosition, threshold]);

    const [selectedLang, setSelectedLang] = useState(() => {
        const currentURL = window.location.href;
        if (currentURL.includes('.nl')) return 'https://www.zwlsoftware.nl';
        if (currentURL.includes('.com')) return 'https://www.zwlsoftware.com';
        return 'https://www.zwlsoftware.nl'; // Default value
      });
    
      const handleChange = (event) => {
        setSelectedLang(event.target.value);
        window.location.href = event.target.value;
      };

    return (
        <>
            <div class="bg">
                
                <div class="flex justify-between  lg:justify-end items-center gap-4 lg:mr-10 h-full w-full">

                    {/* <div className='flex hidden scale items-center gap-2'> <FaPhone />     </div> */}
                    <span class="email ml-4 lg:ml-0 mr-3 flex justify-start items-center gap-2">
                        <span class="mailicon"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M460.6 147.3L353 256.9c-.8.8-.8 2 0 2.8l75.3 80.2c5.1 5.1 5.1 13.3 0 18.4-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8l-75-79.9c-.8-.8-2.1-.8-2.9 0L313.7 297c-15.3 15.5-35.6 24.1-57.4 24.2-22.1.1-43.1-9.2-58.6-24.9l-17.6-17.9c-.8-.8-2.1-.8-2.9 0l-75 79.9c-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8c-5.1-5.1-5.1-13.3 0-18.4l75.3-80.2c.7-.8.7-2 0-2.8L51.4 147.3c-1.3-1.3-3.4-.4-3.4 1.4V368c0 17.6 14.4 32 32 32h352c17.6 0 32-14.4 32-32V148.7c0-1.8-2.2-2.6-3.4-1.4z"></path><path d="M256 295.1c14.8 0 28.7-5.8 39.1-16.4L452 119c-5.5-4.4-12.3-7-19.8-7H79.9c-7.5 0-14.4 2.6-19.8 7L217 278.7c10.3 10.5 24.2 16.4 39 16.4z"></path></svg> </span>info@zwlsoftware.nl</span><span class="lang lg:mr-10 flex justify-start items-center gap-2"><span class="icon"> <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path><path d="M3.6 9h16.8"></path><path d="M3.6 15h16.8"></path><path d="M11.5 3a17 17 0 0 0 0 18"></path><path d="M12.5 3a17 17 0 0 1 0 18"></path></svg> </span>
                        <select
                            className="select-lang nobg"
                            aria-label={t('select-language')}
                            id="select-lang"
                            value={selectedLang}
                            onChange={handleChange}
                            >
                            <option value="https://www.zwlsoftware.nl" id="nl">NL</option>
                            <option value="https://www.zwlsoftware.com" id="en">EN</option>
                        </select>
                    </span></div>

            </div>
            {isSticky ? (
                <div className='w-full bg-white h-[162px] bg-gray-200'>
                    {/* Content or styles for when sticky */}
                </div>
            ) : null}
            <header className={`header bg-white  ${isSticky ? 'sticky  shadow-md' : ''} `}>

                <div className={`content nav-head h-full flex justify-between items-center`} >
                    <div className="lg:px-0 px-6 w-full flex items-center justify-between lg:gap-20 gap-2  ">
                        {/* Logo */}
                        <div className="flex-shrink-0">
                            <div className="text-2xl font-bold logo"><a href='/'> <img src='/zwlBL.png' /></a> </div>

                        </div>

                        {/* Desktop Navigation Links */}
                        <nav className="hidden  lg:flex flex-grow justify-start space-x-6 gap-10 w-full">
                        {navItems.map((item) => (
                            <a
                                key={item.link}
                                id={item.link}
                                href={item.link}
                                className={`relative group ${isActive(item.link) ? 'text-black' : 'text-gray-500'} hover:text-black`}
                            >
                                {item.name}
                                {/* <span className={`absolute left-0 bottom-0 h-[2px] w-full transition-transform duration-300 ease-in-out ${isActive(item.link) ? 'bg-gray-800 scale-x-50' : 'bg-gray-400 scale-x-0'} group-hover:scale-x-20`}></span> */}
                            </a>
                        ))}

                        </nav>

                        {/* Desktop Contact Button */}
                        {/* <div className="hidden lg:block">
                            <Button ripple={false} className='contact-btn-1 rounded px-9 py-2 active:text-white focus:text-white'> <a href="/contact" className="text-white active:text-white focus:text-white hover:text-white w-full h-full text-white px-4 py-2  flex items-center gap-1  ">  Contact </a> </Button>
                        </div> */}
                        <a 
                            id='contact_mobile' 
                            href={t('header.link-contact')} 
                            className='p-2 !text-sm lg:block hidden text-white no-underline hover:text-white mobile-hide text-white submittext' 
                            data-gtm-event="contact_click"
                            data-gtm-category="Contact"
                            data-gtm-label="Contact Button">
                            <Button ripple={false} className='contact-btn-1 !text-white !p-4 submittext'>
                                {t('header.contact')}
                            </Button>
                        </a>

                        {/* Mobile Menu Button */}
                        <div className="lg:hidden flex items-center">
                            <button onClick={toggleMenu} className="text-gray-800 focus:outline-none">
                                {/* <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
                            </svg> */}
                                <Hamburger direction='left' toggled={isOpen} />
                            </button>
                        </div>
                    </div>


                </div>
                {/* Mobile Menu */}

                <div
                    className={`mobile-menu lg:hidden h-full bg-white shadow-md overflow-hidden transition-all duration-300 ease-in-out`}
                    style={{ height }}
                >
                    <nav ref={contentRef} className="flex !pb-12 justify-start items-start flex-col mt-2 px-6 py-4 space-y-2">
                        {navItems.map((item) => (
                            <a
                                key={item.link}
                                href={item.link}
                                className={`relative group ${isActive(item.link) ? 'text-black' : 'text-gray-500'} hover:text-black`}
                            >

                                {item.name}
                                {/* <span className={`absolute left-0 bottom-0 h-[2px] w-full transition-transform duration-300 ease-in-out ${isActive(item.link) ? 'bg-gray-800 scale-x-50' : 'bg-gray-400 scale-x-0'} group-hover:scale-x-20`}></span> */}
                            </a>
                        ))}
                        <div className='w-full flex justify-center items-center mr-4 ml-2'>
                            <Button  ripple={false} className='w-full contact-btn-1 mt-12    ml-2 mr-5 rounded px-9 py-2 active:text-white focus:text-white submittext'> <a id='contact_mobile' href="/contact" className="text-white text-center active:text-white focus:text-white hover:text-white w-full h-full text-white px-4 py-2  flex justify-center items-center gap-1 submittext "> {t('header.contact-cta')} </a> </Button>
                        </div>

                    </nav>
                </div>
                <PageProgressWrapper  />
                <div className={`w-full min-h-[40px] ${isOpen ? "mt-0" : "mt-3"} scroller-container flex justify-center items-center text-white`}>
                    <InfiniteScroll textArray={scrollerText} linkTo={t('header.link-create-website')} />
                </div>
            </header>
          
        </>
    );
};