import { useTranslation } from "react-i18next";
import { Button } from "rsuite";

export const InnovatePannel = () => {
    const { t } = useTranslation('innovatePannel'); 
    const cardsContent = [
        {
            title: t('cardsContent.t1'),
            desc: t('cardsContent.d1'),
            c: "left"
        },
        {
            title: t('cardsContent.t2'),
            desc: t('cardsContent.d2'),
            c: "center"
        },
        {
            title: t('cardsContent.t3'),
            desc: t('cardsContent.d3'),
            c: "right"
        },
    ];
    
    return (
        <>
            <section className=" light-bg w-full pb-20">
                <div className="content pt-20">
                    <h4 className="md:text-center text-start !color-[#545454]  sub-title !font-[300]" data-aos-duration={500} data-aos-delay={100} data-aos="fade-up">
                        {t('title')}
                    </h4>
                    <h2 className="!text-black md:text-center text-start title !font-[400]" data-aos-duration={500} data-aos-delay={400} data-aos="fade-up">
                        {t('sub-title')}
                    </h2>
                    <div className="mt-14 flex md:flex-row flex-col justify-between items-center pb-20   md:gap-5 gap-10">
                        { cardsContent.map((data, index) => (
                            <div className="md:w-1/3 w-full  " data-aos-duration={500} data-aos-delay={index * 300} data-aos="fade-in"> 
                                <div className=" card2 md:pt-12 pt-6 md:px-16 px-12 md:hover:scale-105 duration-300" >
                                    <div>
                                        <h2 className="text-black text-center text-xl pb-5 !font-bold  text-nowrap">{data.title}</h2>
                                        <p className="text-center">{data.desc}</p>
                                    </div>
                                    <div className={data.c}>
                                    </div>
                                </div>
                            </div>
                        ))}
                         
                    </div>
                    <div className="w-full flex md:justify-start justify-center items-center ">
                        <Button ripple={false} className='shadow-md animated animated-bounce contact-btn-1 rounded px-9 py-2 active:text-white focus:text-white submittext'> <a href={t('CTAButton-link')} className="text-white active:text-white focus:text-white hover:text-white w-full h-full text-center text-white px-4 py-2  flex justify-center items-center gap-1  " id="cta_innovate"> {t('CTAButton')} </a> </Button>
                    </div>
                </div>
            </section>
        </>
    );
}