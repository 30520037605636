import { useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";

export const Testje =  () =>{
    const [confettiList, setConfettiList] = useState([]);

    const handleClick = () => {
      const newConfetti = { id: Date.now() };
      
      // Add a new confetti explosion to the list
      setConfettiList((prevList) => [...prevList, newConfetti]);
  
      // Remove the confetti explosion after 10 seconds
      setTimeout(() => {
        setConfettiList((prevList) =>
          prevList.filter((confetti) => confetti.id !== newConfetti.id)
        );
      }, 5000); // 10 seconds in milliseconds
    };
  
    return (
      <div className="min-h-[60vh] flex justify-center confetti-button-container" style={{ position: 'relative' }}>
        <button
          onClick={handleClick}
          className="button button-main"
        >
          Click for Confetti!
        </button>
  
        {confettiList.map((confetti) => (
          <ConfettiExplosion key={confetti.id} />
        ))}
      </div>
    );
  };