import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Input } from 'rsuite';

export const CallModal = ({ show, onClose, formData, onPhoneChange, OnSubmit }) => {
    const { t } = useTranslation('callModal');
    const [isValid, setIsValid] = useState(true);
    const minDigits = 4; // Set the minimum number of digits required
    const maxDigits = 16; // Set the maximum number of digits allowed

    // Validate the phone number
    const validatePhone = (phone) => {
        if (!phone) return false; // Return false if phone is undefined or empty
        const regex = /^\+?[0-9]*$/; // Allow optional '+' at the start followed by numbers
        const digitCount = (phone.match(/[0-9]/g) || []).length; // Count digits in the phone number
        return regex.test(phone) && digitCount >= minDigits && digitCount <= maxDigits; // Ensure valid and meets the digit requirements
    };

    // Handle submit event
    const handleSubmit = () => {
        if (isValid) {
            OnSubmit(formData); // Submit the entire formData back to the parent
        }
    };

    // Handle phone input change
    const handlePhoneChange = (value) => {
        // Allow only numbers and a single '+' at the beginning
        const formattedValue = value.replace(/[^0-9+]/g, ''); // Remove invalid characters

        // Ensure there's only one '+' at the start if present
        if (formattedValue.startsWith('+') && formattedValue.slice(1).includes('+')) {
            formattedValue = formattedValue.replace(/\+/g, ''); // Remove additional '+' signs
            formattedValue = '+' + formattedValue; // Add '+' back to the start
        }

        onPhoneChange(formattedValue); // Update phone in parent
    };

    // Effect to validate phone number on formData.phone change
    useEffect(() => {
        setIsValid(validatePhone(formData.phone)); // Validate phone number
    }, [formData.phone]);

    return (
        <Modal open={show} onHide={onClose} onClose={onClose}>
            <Modal.Header>
                <Modal.Title>{t('enter-phone')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input
                    placeholder="Phone"
                    autoComplete="phone"
                    value={formData.phone || ''} // Ensure there's a default value
                    onChange={handlePhoneChange} // Use the new change handler
                    style={{
                        borderColor: !isValid && formData.phone !== '' ? 'red' : undefined, // Apply red outline if invalid and not empty
                    }}
                />
                <span className='text-xs text-gray-500 mt-1 mb-3 block'>
                    * {t('agreement')}<a href={t('privacy-link')}> {t('privacy')}</a>
                </span>
            </Modal.Body>
            <Modal.Footer>
                <Button id='submit_number' onClick={handleSubmit} className='button !text-white !p-3 contact-btn-1' disabled={!isValid}>
                    {t('submit')}
                </Button>
                <Button onClick={onClose} appearance="subtle">
                    {t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
