import React, { useEffect, useRef } from 'react';

const InfiniteScroll = ({textArray, linkTo}) => {

    const scrollingTextRef = useRef(null);
   
    // Generate the scrolling text dynamically
    useEffect(() => {
        const element = scrollingTextRef.current;
        if (element) {
            element.innerHTML = Array(10).fill(
                textArray.map(text => `<span class="mx-2 text-sm md:text-base">${text}</span>`).join('')
            ).join('');
        }
    }, [textArray]);

    return (
        linkTo && (
            <a href={linkTo} className='w-full h-full min-h-[45px] flex flex-col justify-center  text-white hover:text-white no-underline' id='infinite_scroller'>
                <div className="scroller">
                    <div className="scrolling-text overflow-x-hidden !mt-[-10px]" ref={scrollingTextRef} aria-hidden="true">
                        {/* Dynamic content will be inserted here */}
                    </div>
                </div>
            </a>
        )
    );
};

export default InfiniteScroll;
