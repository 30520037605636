import { Accordion, Button, Placeholder } from 'rsuite';
import { useTranslation } from 'react-i18next';

export const FAQ = () => {
    const { t } = useTranslation('faq');
    const faqQuestions = [
        'questions.faq-question1',
        'questions.faq-question2',
        'questions.faq-question3',
        'questions.faq-question4',
        'questions.faq-question5',
        'questions.faq-question6',
        'questions.faq-question7',
      ];
    return (
        <>
          <h2 className='text-black text-center my-10' data-aos="fade-up"  data-aos-duration="500" >{t('title')}</h2>
          <Accordion  className='mt-5 md:px-6 px-3 faq' >
            {faqQuestions.map((questionKey,index) => (
              <div data-aos="fade-in" data-aos-once="true"  data-aos-offset="100"  data-aos-duration="500" data-aos-delay={500 +    100 * index}  > 
                  <Accordion.Panel bodyFill={false}  className='mt-2 ' key={questionKey} header={t(`${questionKey}`)} >
                    <span className='mt-5 block'>
                      {t('answers.faq-answer'+ (index + 1))}
                    </span>
                  </Accordion.Panel>
              </div>

            ))}
          </Accordion>
          <div className='md:px-6 px-3 mt-8 w-full h-full flex md:justify-start justify-center items-center' data-aos="fade-in" data-aos-delay={200}>
            <Button ripple={false} className='animated contact-btn-1  md:w-1/5 w-full' >
                <a href={t('cta-button-link') } className='p-2 submittext !text-sm block text-white no-underline hover:text-white' id='contact_faq'>{t('cta-button')}</a>

            </Button>
          </div>
        </>
      );
}