import { useTranslation } from "react-i18next";
import { BiConfused } from "react-icons/bi";
export const NotFound = () => {
    const {t} = useTranslation();

    return(
        <div className="min-h-[70vh] ">
            <div className="min-h-[50vh] flex flex-col justify-center items-center">
            <BiConfused className="min-h-[50px] min-w-[50px]" />
              <h1 className="md:text-start text-center">{t('404')}</h1>
              <a href="/" className="text-black hover:text-black"><p className="md:text-start text-center">{t('notexist')}</p></a>
            </div>
        </div>
    );
} 