import { BsArrowRight } from "react-icons/bs";
import { CasesScroller } from "../components/CasesScroller";
import { Button } from "rsuite";
import { useTranslation } from "react-i18next";
import { ContactPannel } from "../components/ContactPannel";
import { Helmet } from "react-helmet";
 

export const Cases = () => {
    const { t } = useTranslation('cases');
  
    return (
        <div className="w-full min-h-[80vh] "
            data-aos-duration={500} data-aos="fade-up"
        >
            <Helmet>
                <meta name="description" content={t('desc')} />
                <link rel="canonical" href={t('canonicalUrl')} />
            </Helmet>
            <div className="content py-10  px-2 ">
                <h1 className="title mb-3 text-black md:text-start text-center !font-[600]" data-aos="fade-down" data-aos-duration={500} data-aos-delay={400}>{t('title')}</h1>

                <h4 className="text-black md:text-start text-center mb-4" data-aos="fade-down" data-aos-duration={500} data-aos-delay={100}>{t('sub-title')} </h4>

                <div className="flex md:flex-row flex-col gap-8 items-center md:justify-start justify-center py-4"

                >
                    <a href={t('cta-link')} className="text-gray-500 hover:text-black group flex items-center gap-2 justify-start">
                        {t('cta')}

                        <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2 mt-[2px]">
                            <BsArrowRight />
                        </span>
                    </a>
                </div>
                <section className="cases pb-10 my-4 md:px-0 px-3">
                    <CasesScroller />
                </section>



            </div>
            <div className="w-full pt-10"> 
                <ContactPannel helpSection={true} />
            </div>
        </div>
    );
}