import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FaPaintBrush, FaGoogle, FaMobileAlt, FaHandsHelping } from 'react-icons/fa';

export const FeaturePannel = () => {
    const {t} = useTranslation('featurePannel');
        
    const cardData = [
        {
            icon: <FaPaintBrush />,
            title:  t('cardData.t1'),
            description: t('cardData.desc1'),
        },
        {
            icon: <FaMobileAlt />,
            title:  t('cardData.t2'),
            description: t('cardData.desc2'),
        },
        {
            icon: <FaGoogle />,
            title:  t('cardData.t3'),
            description: t('cardData.desc3'),
        },
    
        {
            icon: <FaHandsHelping />,
            title:  t('cardData.t4'),
            description: t('cardData.desc4'),
        }
    ];
    return (
        <>
            <div className="w-full flex flex-wrap items-center ">
                <div className="w-full md:w-1/3 mb-6 md:mb-0  px-6">
                    <img src="/img/634.jpg" className=" w-full h-full object-cover"  data-aos-duration={500} data-aos="fade-in"/>
                </div>
                <div className="w-full md:w-2/3 md:gap-y-10 flex flex-wrap">
                    {cardData.map((card, index) => (
                        <div key={index} className="animated !duration-500 lg:w-1/2 w-full p-4 flex" >
                            <div className='mdcard1 max-h-[300px] !m-0 md:p-8 flex flex-col items-center justify-start' data-aos-duration={500 + index * 50 }   data-aos="fade-up">
                                <div className="svgicon my-4 w-12 h-12 bf-card">{card.icon}</div>
                                <div>
                                    <h3 className="md:text-center font-bold">{card.title}</h3>
                                    <p className='md:text-center '>{card.description}</p>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>

            </div>
        </>
    );
};
