import { useTranslation } from "react-i18next";
import { BiConfused } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
export const WebsiteTemplates = () => {
    const {t} = useTranslation();

    return(
        <div className="min-h-[70vh] ">
            <div className="min-h-[50vh] flex flex-col justify-center items-center">
            <BiConfused className="min-h-[50px] min-w-[50px]" />
              {/* <h1>{t('404')}</h1> */}
              <p>{t('toexists')}</p>
                <a href={"/"} className="text-gray-500 hover:text-black group flex items-center gap-2 justify-start">
                <p>{t('back-to-home')}</p>

                <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2 mt-[2px]">
                    <BsArrowRight />
                </span>
                </a>
         
            </div>
        </div>
    );
} 