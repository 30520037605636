import { useTranslation } from "react-i18next";
import { Button } from "rsuite";

export const ContactPannel = ({ helpSection }) => {
    const {t} = useTranslation("contactPannel");
    const links = [
        { label: "← "  + t('helpcenter'), href: t('links.helpcenter')  },
        { label: "← "  + t('create-website'), href: t('links.create-website')  },
        { label: "← "  + t('contact'), href: t('links.contact') }
    ];

    return (
        <>
            <div className="w-full md:px-0 px-6">
                <div className="content py-20 flex md:gap-0 gap-10 flex-wrap md:flex-row flex-col-reverse justify-center"   >
                    <div className=" md:w-1/2 w-full flex flex-col gap-10 justify-center">
                        <h3 className="!font-[500]">{t('we-are-ready')}</h3>
                        <p className="md:w-[80%]">
                                {t('more-than')}
                        </p>
                        <Button ripple={false} className='shadow-md w-1/2 animated animated-bounce contact-btn-1 rounded px-9 py-2 active:text-white focus:text-white'>
                            <a href="/contact" className="text-white active:text-white focus:text-white hover:text-white w-full h-full text-center text-white px-4 py-2 flex justify-center items-center gap-1 submittext" id="cnpannel_cta_contact">
                                {t('contactCTA')}
                            </a>
                        </Button>

                       
                    </div>
                    <div className="md:w-1/2 w-full flex justify-end items-center">
                        <div className="img-container"  data-aos="fade-in" data-duration="300">
                            <img src="/img/022.jpg" alt="Contact"/>
                        </div>
                    </div>
                </div>
            </div>

            {helpSection && (
                <>
                    <div className="bg-2 py-10 w-full min-h-[30vh] md:px-0 px-6">
                        <div className="content">
                            <div className="flex flex-col gap-3">
                                <h3>{t('need-help')}</h3>
                                <p>{t('need-help-desc')}</p>
                                 {/* Add the links below the p tag */}
                                <div className="mt-5 flex flex-col gap-5">
                                    {links.map((link, index) => (
                                        <a key={index} href={link.href} className="block text-gray-700 hover:text-gray-900" >
                                            {link.label}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
