// src/utils/getSubdomain.js
import { allowedSubdomains } from './allowedSubdomains';

export const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.length > 2) {
    const subdomain = parts[0];
    if (allowedSubdomains.includes(subdomain)) {
      return subdomain;
    }
  }
  return null;
};
