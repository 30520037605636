import { TbDeviceDesktopCode } from "react-icons/tb";
import { Button, ButtonGroup } from "rsuite";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { FaCheck, FaLongArrowAltRight } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import PulsingSquares from "./PulseSquares";
import { FAQ } from "./FAQ";
import { Reviews } from "./Reviews";
import { Features } from "./Features";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { CTAContact } from "./CTAContact";
import CTAMouseLeaveAlert from "./CTAMouseLeaveAlert";
import VerticalScrollSlider from "./VerticalScrollSlider";
import { AZpannel } from "./AZPanel";
import { WebFeatures } from "./WebFeatures";
import { InnovatePannel } from "./InnovatePannel";
import { TextBanner } from "./TextBanner";
import { ContactPannel } from "./ContactPannel";
export const LandingPage = () => {

    const { t } = useTranslation();
    // ZWL Zakelijke Website Leverancier
    // Zakelijke Web- en Logicasoftware
    return (
        <div className="md:mt-20 mt-5  ">
            <CTAMouseLeaveAlert />

            <div className="content md:px-0 px-6 md:flex md:flex-nowrap flex-wrap  gap-20 justify-center items-center ">
                <div className="md:hidden block md:mb-0 mb-10"><PulsingSquares /></div>

                <div className="md:w-full ">
                    <div className="flex justify-start items-center ">
                        <h1 class="title pb-8  md:min-w-[130%] !text-black !font-bold">
                           {t('home-title')}

                        </h1>
                    </div>
                 
                    {/* <h5 className="my-6 title2 "  > Webdesign op maat voor jouw website, zodat jij online kunt schitteren. we luisteren naar jouw wensen, analyseren je doelgroep en creëren een unieke en website die bij jouw bedrijf past.  </h5> */}
                    <h5 className=" mb-6 title2 text-black-400   md:mt-2 mt-4"    > {t('home-subtitle')}</h5>
                    {/* <hr className="mt-2 mb-5">
                    </hr> */}
                    <div className="md:flex md:flex-nowrap flex-wrap items-center gap-4 mb-10 mr-10">
                        {/* <span className="flex mt-1 items-center gap-2"> <FaCheck color="green" /> {t('sub-graphic-design')} </span>
                        <span className="flex mt-1 items-center gap-2"> <FaCheck color="green" /> {t('sub-tailor-web')} </span>
                        <span className="flex mt-1 items-center gap-2"> <FaCheck color="green" /> {t('sub-seo')}</span>
                        <span className="flex mt-1 items-center gap-2 "> <FaCheck color="green" /> {t('sub-hosting-domains')}   </span> */}

                    </div>


                    <div className="flex md:flex-row flex-col  gap-5 justify-start items-center w-full">
                        <a className="w-full md:w-1/3  animated" href={t('link-create-website')} id="cta_hero1">
                            <Button ripple={false} className="btn-main w-full  !py-4 rounded" >
                            
                                    <span className="w-full flex justify-center items-center gap-2 text-white hover:text-white" >
                                    {/* Website laten maken */}
                                        {t('build-website-cta')}

                                    </span>
                                    
                            
                            </Button>
                        </a>
                       
                      
                        <a  href={t('link-contact')} className="text-black hover:text-black group flex items-center gap-2 justify-start" id="cta_hero2">
                            {t('contact-cta')}

                                <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2 mt-[2px]">
                                    <BsArrowRight />
                                </span>
                        </a>
                     
                    </div>
                </div>
                <div className="  container md:flex items-center justify-center   hidden md:w-1/2 ">

                    <PulsingSquares />

                </div>
            </div>

            <div className="md:px-0 px-6 w-full md:mt-60 mt-20 md:flex md:flex-nowrap flex-wrap  gap-10 justify-center items-center  ">
                <InnovatePannel /> 
            </div>

            <div className="w-full relative !z-[99]">
                <TextBanner  />
            </div>

            <div className=" w-full  ">
                <Reviews cta_={false} />
            </div>
            <div className=" w-full  ">
                <ContactPannel helpSection={true} />
            </div>

           
        </div>
    );
}