import { useTranslation } from "react-i18next";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { TbFileArrowRight } from "react-icons/tb";
import { Button, Form, Input } from "rsuite";
import { CallModal } from "./CallModal";
import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import axios from "axios";

export const QuotePannel = () => {
    const [showModal, setShowModal] = useState(false); 
    const [err, setError] = useState(''); 
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: ''  // Ensure phone is part of the initial state
    });



    const [hasSubmitPhoneContent, setHasSubmitPhoneContent] = useState(false); 
    const {t} = useTranslation('quotePannel');
    const [submittingData, SetSubmittingData] = useState(false);
    const StepsContent = [
        {
            title: t('stepsContent.t1'),
        },
        {
            title:  t('stepsContent.t2'),
        },
        {
            title:  t('stepsContent.t3'),
        },
    ]
    const handleOpen = () => {
        console.log("show");
        
        setShowModal(true);
    };
    const handleClose = () => setShowModal(false);

    const [confettiList, setConfettiList] = useState([]);

    const handleConfetti = () => {
        setHasSubmitPhoneContent(true);
        handleClose();
        const newConfetti = { id: Date.now() };
        
        // Add a new confetti explosion to the list
        setConfettiList((prevList) => [...prevList, newConfetti]);
    
        // Remove the confetti explosion after 10 seconds
        setTimeout(() => {
            setConfettiList((prevList) =>
            prevList.filter((confetti) => confetti.id !== newConfetti.id)
            );
            setHasSubmitPhoneContent(false);

        }, 2500); 

    };
    // Handle form input changes
    const handleChange = (value, name) => {
        setFormData({ ...formData, [name]: value });
    };
    // Update phone field within formData
    const handlePhoneChange = (phone) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            phone
        }));
    };

    const handleQuoteSubmission = async () => {
        SetSubmittingData(true);

        try {
            const response = await axios.post('https://api.zwlsoftware.nl/zwlsoftware/index.php', {
                func: "save_cus",
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            
            console.log(response.data); // Handle successful response
            if(response.data?.code == 0)
            {
                return alert(t('already-exist'))
            }
            setFormData({ name: '', email: '' }); // Reset form
            handleConfetti();

        } catch (error) {
            
            console.error(error); // Handle error
        } finally {
            //do something when done?
            
        }

        setTimeout(() => {
            SetSubmittingData(false);
        }, 5000);
    }
  

    return (

        <>
         
            {hasSubmitPhoneContent && (
                <div className="absolute w-screen h-screen">
                    <div className="float-left">{confettiList.map((confetti) => (
          <ConfettiExplosion key={confetti.id} />
        ))}</div>
                    <div className="float-right">{confettiList.map((confetti) => (
          <ConfettiExplosion key={confetti.id} />
        ))}</div>
                </div>
            )}
            <CallModal show={showModal} formData={formData} onClose={() => handleClose()} OnSubmit={() => handleQuoteSubmission()}  onPhoneChange={handlePhoneChange} />
            <section className=" light-bg w-full pt-20 pb-20">
                <div className="content ">
                    <div className="bg-[#fff] w-full min-h-[350px] rounded-xl shadow-lg flex flex-wrap ">
                        <div className="md:w-1/2 w-full flex flex-col justify-between items-start md:p-10   px-6">
                            <h2 className="text-black !font-[600] new-title pt-5">{t('title')}</h2>
                                <div className="steps flex justify-center w-full flex-row md:gap-20 gap-5 py-10 ">
                                {StepsContent.map((data, index) => (
                                    <div className="flex flex-col items-center ">
                                        <div className="md:w-14 md:h-14 w-10 h-10 bg-[#d9d9d9] rounded-full flex justify-center items-center text-black">
                                            <span className="md:text-md text-sm">{index}</span>
                                        </div>

                                        <span className="text-center md:text-md text-sm">{data.title}</span>
                                    </div>

                                ))}
                            </div>

                        </div>
                        <div className="md:w-1/2 w-full flex flex-wrap justify-end items-center">
                            <div className=" bg-[#ff66b3] md:rounded h-[100%] md:w-[60%] w-full flex justify-start items-center ">
                                <div className=" rounded-xl bg-[#4D4FFF] h-[83%] md:ml-[-100px] w-[100%] p-5 md:mb-0 mb-[100px] mx-5 shadow-md">
                                    <h3 className="text-white !font-[500] py-3">
                                        {t('cta-contact')}
                                    </h3>
                                    <Form className="mt-2 flex flex-col gap-4" onSubmit={() => handleQuoteSubmission()}>
                                        <Input className="!p-3" placeholder={t('form.placeholder-name')}  id="name-subm"
                                        value={formData.name}
                                        autoComplete="name"
                                        maxLength={43}
                                             onChange={(value) => handleChange(value, 'name')}
                                        />
                                        <Input className="!p-3" placeholder={t('form.placeholder-email')} id="email-subm" 
                                        value={formData.email}
                                        maxLength={95}

                                        autoComplete="email"
                                        onChange={(value) => handleChange(value, 'email')}
                                        />
                                        <a id="quote_phone_menu" className="cursor-pointer text-white hover:text-white group flex items-center gap-2 justify-start"  onClick={() => handleOpen()}>
                                            {t('call-cta')}

                                            <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2 mt-[2px]">
                                                <BsArrowRight />
                                            </span>
                                        </a>
                                        <div className="w-full md:mt-[-15px] flex justify-end">
                                            <div className="w-1/2">
                                                <Button ripple={false} type="submit" className="animated animated-bounce button btn-last w-full min-h-[20px] !text-black !font-[700] !p-4 submittext" disabled={submittingData} id="quote_send" >{t('form.cta-send')} </Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2 w-full flex flex-col gap-4 mt-10 pt-10 md:px-0 px-6">
                        <h4 className="text-black  !font-[600]">{t('templates.title')}</h4>
                        <a href={t('templates.cta-link')} className="text-gray-500 hover:text-black group flex items-center gap-2 justify-start">
                           {t('templates.cta')}
                            <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2">
                                <BsArrowRight />
                            </span>
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
}