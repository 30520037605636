import React, { useEffect, useState } from 'react';

const CTAMouseLeaveAlert = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
      if (scrollTop / scrollHeight >= 0.2) {
        setHasScrolled(true);
      }
    };

    // Function to handle the mouse leaving the document
    const handleMouseLeave = (event) => {
      if (hasScrolled && !alertShown &&
          (event.clientY <= 0 || event.clientX <= 0 || 
           event.clientX >= window.innerWidth || 
           event.clientY >= window.innerHeight)) {
        // alert('You are leaving the site!');
        setAlertShown(true);
      }
    };

    // Add the event listeners
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mouseleave', handleMouseLeave);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [hasScrolled, alertShown]);

  return null; // This component doesn't render anything
};

export default CTAMouseLeaveAlert;
