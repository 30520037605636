import axios from "axios";
import { useEffect, useState } from "react";
import { Loader } from "rsuite";
import { NotFound } from "./NotFound";
import { QuotePannel } from "../components/QuotePannel";
import { ContactPannel } from "../components/ContactPannel";
import { BsArrowRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { FaExternalLinkAlt } from "react-icons/fa";

export const Case = () => {
    const [submittingData, SetSubmittingData] = useState(true);
    const [pageData, SetPageData] = useState('');
    const {t} = useTranslation('case'); 
    useEffect(() => {
        fetchPage();
    }, []);
    const [domain, setDomain] = useState('');

    useEffect(() => {
      const host = window.location.host;
      const parts = host.split('.');
      const domainName = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
      setDomain(domainName);
    }, []);

    const getLastPathSegment = () => {
        const path = window.location.pathname;
        const segments = path.split('/');
        return segments[segments.length - 2] || segments[segments.length - 1]; // Handles trailing slash
    };
    const fetchPage = async () => {

        try {
            SetSubmittingData(true);


            const response = await axios.post('https://api.zwlsoftware.nl/zwlsoftware/index.php', {
            // const response = await axios.post('http://localhost/zwlsoftware/index.php', {
                func: 'fetch_page',
                page: getLastPathSegment(),

            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            console.log(response.data); // Handle successful response
            if (response.data?.code == 0) {
                //not found
                return;
            }

            SetPageData(response.data);

        } catch (error) {
            SetPageData(false);
            console.error(error); // Handle error
        } finally {
            SetSubmittingData(false);


        }


    }


    return (
        <div className="page">
            <div className="content">
                <div className="case-data flex justify-center">
                    <div className="loader flex flex-col items-center">
                        {pageData == '' && submittingData && (
                            <>
                                <Loader size="md" />

                            </>
                        )}
                    </div>
                    <div>
                        {pageData == '' && !submittingData && (
                            <NotFound />
                        )}
                    </div>

                    {pageData && (
                        <div className="w-full py-10 md:px-0 px-6">
                            <div className="img-container3">
                                <div className="img">
                                    <a className="w-full" href={ pageData?.data?.isSelfHosted === 1 ?  "https://"+pageData?.data?.seo + "." + domain : pageData?.data?.url} target="_blank">
                                        <img className="animated" src={`/cases/` + pageData?.data?.id + '.png'} />
                                    </a>
                                </div>
                            </div>

                            <div className="w-full py-10">
                                <h1> {pageData?.data?.name} </h1>
                                <p className="pt-5" dangerouslySetInnerHTML={{__html: t('descs.'+pageData?.data?.desc) }} />
                            </div>
                            
                            <a href={pageData?.data?.url} target="_blank" className="text-gray-500  hover:text-black group flex items-center gap-2 my-2 justify-start">
                                {t('openweb')}

                                <span className="inline-block transform  mt-[2px]">
                                    <FaExternalLinkAlt />
                                </span>
                            </a>



                            <a href={t('cta-link')} className="text-gray-500 hover:text-black group flex items-center gap-2 justify-start">
                                {t('cta')}

                                <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2 mt-[2px]">
                                    <BsArrowRight />
                                </span>
                            </a>

                        
                        </div>



                    )}
                    
                </div>
            </div>
            {pageData && (
            <div className="w-full pt-10"> 
                <div className="">
                    <QuotePannel />
                </div>
                
                <ContactPannel helpSection={true} />
            </div>
            )}
        </div>
    );
}