import { useTranslation } from "react-i18next";
import { BiSupport } from "react-icons/bi";
import { FaBrush, FaCode, FaHeart, FaSearch } from "react-icons/fa";
import { MdDesignServices } from "react-icons/md";
import { TbDevicesStar } from "react-icons/tb";

export const TextBanner = ({ WebCards }) => {
    const {t} = useTranslation('textBanner'); 
    
    const WebCardsContent = [
        {
            icon: <FaBrush />,
            title: t('WebCardsContent.t1'),
            desc: t('WebCardsContent.d1'),
        },
        {
            icon: <FaCode />,
            title: t('WebCardsContent.t2'),
            desc: t('WebCardsContent.d2'),
        },
        {
            icon: <FaSearch />,
            title: t('WebCardsContent.t3'),
            desc: t('WebCardsContent.d3'),
        },
        {
            icon: <TbDevicesStar />,
            title: t('WebCardsContent.t4'),
            desc: t('WebCardsContent.d4'),
        },
        {
            icon: <FaHeart />,
            title: t('WebCardsContent.t5'),
            desc: t('WebCardsContent.d5'),
        },
        {
            icon: <BiSupport />,
            title: t('WebCardsContent.t6'),
            desc: t('WebCardsContent.d6'),
        },
    ];


    return (<>
        <section className={`text-banner !z-[99] ${WebCards ? "md:px-0 px-6" : "px-0"}`}>
            <div className={`!z-[99] flex ${WebCards ? "flex-col" : "md:flex-row px-10"} flex-col content min-h-[280px] h-full`} data-aos="fade-up" >

                <div className={`py-5  ${WebCards ? "w-full" : "md:w-1/2"}  w-full md:mt-0 mt-5 flex justify-start items-center `}>
                    <div className="md:w-[70%]">
                        <h2 className={`text-white title !font-[200]  ${WebCards ? "mt-12 !font-[500]" : "mt-0"} `}> 

                            {WebCards ? (
                                <>  {t('title-cards')} </>
                            ) : (
                                <>  {t('title-nocards')} </>
                            )}

                        </h2>

                    </div>
                </div>
                {WebCards ? (
                    <div className="flex flex-wrap   ">
                        {WebCardsContent.map((data, index) => (
                            <div className={ ` animated !duration-500 p-2 flex flex-col items-start justify-start w-full md:w-1/3   ${index < 6  ? " !mb-[-40px]" : "!m-0"}`} key={index}>
                                <div className="card1 w-full  h-full p-10">
                                    <div className="svgicon my-4 w-12 h-12 bf-card">{data.icon}</div>
                                    <h3 className="text-start">{data.title}</h3>
                                    <p>{data.desc}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                ) : (
                    <div className="md:w-1/2 w-full text-white flex justify-start items-center  md:mb-0 mb-10">
                        <span className="text-3xl flex justify-center items-center">
                            <p className="md:w-[70%]    font-[200]">
                                {t('title-main')}
                                
                            </p>

                        </span>
                    </div>
                )}

            </div>
        </section>
    </>);
}