// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import nlTranslations from './locales/nl/translation.json';
import enContactPannel from  './locales/en/ContactPannel.json';
import nlContactPannel from  './locales/nl/ContactPannel.json';

import enCases from './locales/en/Cases.json';
import nlCases from './locales/nl/Cases.json';
import enContact from './locales/en/Contact.json';
import nlContact from './locales/nl/Contact.json';
import enCreateWebsite from './locales/en/CreateWebsite.json';
import nlCreateWebsite from './locales/nl/CreateWebsite.json';
import enHeaderBanner from './locales/en/HeaderBanner.json';
import nlHeaderBanner from './locales/nl/HeaderBanner.json';
import enHero from './locales/en/Hero.json';
import nlHero from './locales/nl/Hero.json';
import enInnovatePannel from './locales/en/InnovatePannel.json';
import nlInnovatePannel from './locales/nl/InnovatePannel.json';
import enReviews from './locales/en/Reviews.json';
import nlReviews from './locales/nl/Reviews.json';
import enTextBanner from './locales/en/TextBanner.json';
import nlTextBanner from './locales/nl/TextBanner.json';
import enWhatsappWidget from './locales/en/WhatsappWidget.json';
import nlWhatsappWidget from './locales/nl/WhatsappWidget.json';
import enApp from './locales/en/App.json';
import nlApp from './locales/nl/App.json';
import enFeaturePannel from './locales/en/FeaturePannel.json';
import nlFeaturePannel from './locales/nl/FeaturePannel.json';
import enWebPackages from './locales/en/WebPackages.json'
import nlWebPackages from './locales/nl/WebPackages.json'
import enQuotePannel from './locales/en/QuotePannel.json'
import nlQuotePannel from './locales/nl/QuotePannel.json'
import enFAQ from './locales/en/FAQ.json'
import nlFAQ from './locales/nl/FAQ.json'
import enFooter from './locales/en/Footer.json'
import nlFooter from './locales/nl/Footer.json'
import enCallModal from './locales/en/Callmodal.json'
import nlCallModal from './locales/nl/Callmodal.json'
import enCase from './locales/en/Case.json'
import nlCase from './locales/nl/Case.json'
import enCookieBanner from './locales/en/CookieBanner.json'
import nlCookieBanner from './locales/nl/CookieBanner.json'
 

// Function to detect the language based on the domain
const detectLanguage = () => {
  const domain = window.location.hostname;

  if (domain.endsWith('.nl')) {
    return 'nl'; // Dutch for .nl domain
  } else {
    return 'en'; // Default to English for other domains
  }
};
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
        contactPannel: enContactPannel,
        cases: enCases,
        contact: enContact,
        createWebsite: enCreateWebsite,
        headerBanner: enHeaderBanner,
        hero: enHero,
        innovatePannel: enInnovatePannel,
        reviews: enReviews,
        textBanner: enTextBanner,
        whatsappWidget: enWhatsappWidget,
        app: enApp,
        featurePannel: enFeaturePannel,
        webPackages: enWebPackages,
        quotePannel: enQuotePannel,
        faq: enFAQ,
        footer: enFooter,
        callModal: enCallModal,
        case: enCase,
        cookieBanner: enCookieBanner
      },
      nl: {
        translation: nlTranslations,
        contactPannel: nlContactPannel,
        cases: nlCases,
        contact: nlContact,
        createWebsite: nlCreateWebsite,
        headerBanner: nlHeaderBanner,
        hero: nlHero,
        innovatePannel: nlInnovatePannel,
        reviews: nlReviews,
        textBanner: nlTextBanner,
        whatsappWidget: nlWhatsappWidget,
        app: nlApp,
        featurePannel: nlFeaturePannel,
        webPackages: nlWebPackages,
        quotePannel: nlQuotePannel,
        faq: nlFAQ,
        footer: nlFooter,
        callModal: nlCallModal,
        case: nlCase,
        cookieBanner: nlCookieBanner
      },
    },
    lng: detectLanguage(), // Set the detected language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
