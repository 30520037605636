import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { Button } from "rsuite";

export const WebPackages = () => {
    const { t } = useTranslation('webPackages');

    const packsData = [
        {
            title: t('packsData.0.title'),  // Accessing the first title
            desc: t('packsData.0.desc'),     // Accessing the first description
            features: [
                t('features.support'),
                t('features.owner'),
                t('features.seo-insurance'),
                t('features.free-changes'),
                t('features.free-mnt')
            ]
        },
        {
            title: t('packsData.1.title'),  // Accessing the second title
            desc: t('packsData.1.desc') ,    // Accessing the second description
            features: [
                t('features.web-idea'),

                t('features.support'),
                t('features.owner'),
                t('features.seo-insurance'),
                t('features.free-changes'),
                t('features.free-mnt')
            ]
        },
        {
            title: t('packsData.2.title'),  // Accessing the third title
            desc: t('packsData.2.desc'),     // Accessing the third description
            features: [
                t('features.self-cus'),
                t('features.support'),
                t('features.owner'),
                t('features.seo-insurance'),
                t('features.free-changes'),
                t('features.free-mnt')
    
            ]
        }
    ];


    return (
        <>
            <div className="w-full py-10">
                <h4 className="text-black text-center" data-aos="fade-down" data-aos-duration={500} data-aos-delay={400}>{t('title')}</h4>
                <h2 className="title text-black text-center !font-[600]" data-aos="fade-down" data-aos-duration={500} data-aos-delay={100}>{t('sub-title')}</h2>
            </div>

            <div className="flex flex-wrap md:flex-row flex-col justify-center items-center">
                {packsData.map((data, index) => (
                    <div  className="lg:w-1/3 w-full " data-aos-duration={500} data-aos-delay={index * 300} data-aos="fade-in">
                    <div className="animated mt-4 px-6 flex flex-col justify-between items-center" key={index} >
                        <div className="head w-full min-h-[60px] rounded-md bg-[#4d4fff]">
                            <h2 className="title2 !font-[500] py-4 text-center text-white">{data.title}</h2>
                        </div>
                        <div className="body flex justify-center rounded flex items-center justify-center bg-[#ebedff] pt-5 w-full h-full">
                        <div className="card1 !min-h-[490px] max-w-[300px] p-5 mx-3">
                            <div className="flex flex-col h-screen justify-between lg:max-h-[420px] max-h-[493px] justify-between"> {/* Remove items-center */}
                                <b className="pb-5">{data.desc}</b>
                                
                                <div className="flex flex-col items-center justify-center flex-grow gap-3"> {/* Added flex-grow here */}
                                    {data.features.map((feature, index) => (
                                        <div className="w-full flex gap-3 my-1 justify-between items-center checkmarks" key={index}>
                                            <div className="checkmark flex justify-start items-center">
                                                <FaCheck color="#008000" />
                                            </div>
                                            <span className="block w-full text-start"> {feature} </span>
                                        </div>
                                    ))}
                                </div>

                                <div className="h-1/2 min-h-[80px] flex justify-end">
                                    {/* CTA Button positioned to reach the bottom */}
                                    <a href="#quote-request" className="w-full flex justify-center mt-auto " id="quote_btn"> {/* Using mt-auto to push it to the bottom */}
                                        <Button className="button contact-btn-1 md:w-[30%] w-full !p-4">
                                            <span className="text-white"> {t('cta-button')} </span>
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        </div>


                        </div>
                    </div>
                    </div>
                ))
                }

            </div>
        </>
    );
}