// analytics.js
import ReactGA from 'react-ga4';

export const InitGA = () => {
  ReactGA.initialize('G-KFT1K22L1F');
  setTimeout(() => {
    ReactGA.send('pageview');
    console.log("Pageview");
    
  }, 1000); // 1 second delay
  
  
};

 