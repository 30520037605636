import React from 'react';
import '../css/pulseart.min.css';
import Typewriter from 'typewriter-effect';
 
const PulsingSquares = () => {
 
  return (
   <>
    <div className='flex justify-center md:mr-0 w-full md:hover:scale-105 duration-300' >
      <div className='md:w-1/2 md:pr-0 pr-8 md:min-h-[300px] min-h-[170px] h-full flex justify-end items-end' data-aos="fade-up" data-aos-delay="80" data-aos-offset="250" >
        <div className='square left w-[120px] h-[120px]' data-aos="fade-up" ></div>

      </div>
      <div className='md:w-1/2 md:mr-2 mr-14  md:min-h-[380px] min-h-[230px] h-full flex flex-col justify-between items-end ' data-aos="fade-up" data-aos-delay="300"  >
        <div className='square center w-[135px] h-[135px]' ></div>
 
        <div className='square mr-[-30px] right w-[100px] h-[100px]' data-aos="fade-in" data-aos-delay="800" ></div>
  
      </div>
    </div>
   </>
  );
};

export default PulsingSquares;
