import React from 'react';
import { useTranslation } from 'react-i18next';
const PrivacyPolicy = () => {
    const {t} = useTranslation();

    return (
     
        <div className="privacy-policy content p-10 w-full" dangerouslySetInnerHTML={{ __html: t('full-policy') }} />
      
    );
};

export default PrivacyPolicy;
