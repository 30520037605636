import { useTranslation } from 'react-i18next';
import { MdOutlineTimer } from 'react-icons/md';
import useScrollPosition from '../hooks/useScrollPosition';
import { useEffect, useState } from 'react';


export const WebsiteLatenMaken_BLOG = () => {
    const scrollPosition = useScrollPosition();
    const [progressVisable, setProgressVisable] = useState();
    // Adjust the scroll threshold percentage here
    const threshold = 0.08 * window.innerHeight; // 25% of viewport height
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        if (scrollPosition > threshold) {
            setProgressVisable(true);


        } else {
            setProgressVisable(false);
        }

        console.log(scrollPosition);

    }, [scrollPosition, threshold]);


    const { t } = useTranslation();
    return (
        <div className="min-h-[70vh]" id='main-content'>
            <div className='md:w-1/2 w-[90%] mx-auto my-10'>
                <div className='flex gap-1 items-center color-gray-400 text-gray-400 '><MdOutlineTimer /> - 3min | Website laten maken in 2024</div>
                <h5 className='mt-3 text-md'>{t('build-web.article.start-title')}:</h5>
                <h1 className='mb-3 mt-1 text-4xl '>{t('build-web.article.title')}</h1>
                <p className='my-5'>{t('build-web.article.paragraphs.intro')}</p>
                <p className='my-5'>{t('build-web.article.paragraphs.costs')}</p>
                <p className='my-5'>{t('build-web.article.paragraphs.solution_intro')}</p>
                <p className='my-5'>{t('build-web.article.paragraphs.solution_intro')}</p>
                <img className='mt-10 mb-3 rounded' src='/img/web/pexels-olly-3763998.jpg' />
                <h3 className='text-black mb-5 mt-10'>{t('build-web.article.sub-titles.seo')}</h3>
                <p className='my-5'>{t('build-web.article.paragraphs.seo1')}</p>
                <p className='my-5'>{t('build-web.article.paragraphs.seo2')}</p>

                <img className='mt-10 mb-3 rounded' src='/img/web/pexels-pixabay-4158.jpg' />
                <h3 className='text-black my-5'>{t('build-web.article.sub-titles.responsive')}</h3>
                <p className='my-5'>{t('build-web.article.paragraphs.responsive')}</p>

                <img className='mt-10 mb-3 rounded' src='/img/web/pexels-steve-963056.jpg' />
                <h3 className='text-black my-5'>{t('build-web.article.sub-titles.quality')}</h3>
                <p className='my-5'>{t('build-web.article.paragraphs.quality')}</p>

                {/* <img className='mt-10 mb-3 rounded' src='/img/web/pexels-divinetechygirl-1181345.jpg' />
                <h3 className='text-black my-5'>{t('build-web.article.sub-titles.userfriendly')}</h3>
                <p className='my-5'>{t('build-web.article.paragraphs.userfriendly')}</p> */}

            </div>

        </div>
    );
} 